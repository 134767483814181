import commonActions from './actions';
interface Action {
    type: string;
    payload: any;
}

const initialState = {
    totalAssetsCount: 9999,
    loadedAssetsCount: 0,
    showMenuAboutModal: false,
    showMenuTeamModal: false,
    showMenuRoadmapModal: false,
    showMenuResidentModal: false,
    showTomoTokenModal: false,
    showTomoTvModal: false,
    showFloppyModal: false,
    showKonbiniWarsModal: false,
    showMetalheadsModal: false,
};

const CommonReducer = (state = initialState, action: Action): any => {
    switch (action.type) {
        case commonActions.SET_TOTAL_ASSETS_COUNT:
            return {
                ...state,
                totalAssetsCount: action.payload.count,
            };
        case commonActions.ADD_LOADED_ASSETS_COUNT:
            return {
                ...state,
                loadedAssetsCount: state.loadedAssetsCount + 1,
            };
        case commonActions.RESET_LOADED_ASSETS_COUNT:
            return {
                ...state,
                totalAssetsCount: 9999,
                loadedAssetsCount: 0,
            };

        case commonActions.TOGGLE_MENU_ABOUT_MODAL:
            return {
                ...state,
                showMenuAboutModal: !state.showMenuAboutModal,
            }

        case commonActions.TOGGLE_MENU_TEAM_MODAL:
            return {
                ...state,
                showMenuTeamModal: !state.showMenuTeamModal,
            }

        case commonActions.TOGGLE_MENU_ROADMAP_MODAL:
            return {
                ...state,
                showMenuRoadmapModal: !state.showMenuRoadmapModal,
            }

        case commonActions.TOGGLE_MENU_RESIDENT_MODAL:
            return {
                ...state,
                showMenuResidentModal: !state.showMenuResidentModal,
            }
    
        case commonActions.TOGGLE_METALHEADS_MODAL:
            return {
                ...state,
                showMetalheadsModal: !state.showMetalheadsModal,
            }

        case commonActions.TOGGLE_TOMO_TOKEN_MODAL:
            return {
                ...state,
                showTomoTokenModal: !state.showTomoTokenModal,
            }

        case commonActions.TOGGLE_TOMO_TV_MODAL:
            return {
                ...state,
                showTomoTvModal: !state.showTomoTvModal,
            }
        case commonActions.TOGGLE_FLOPPY_MODAL:
            return {
                ...state,
                showFloppyModal: !state.showFloppyModal,
            }

        case commonActions.TOGGLE_KONBINI_WARS_MODAL:
            return {
                ...state,
                showKonbiniWarsModal: !state.showKonbiniWarsModal,
            }

        default:
            return state;
    }
};

export default CommonReducer;
