import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes/index';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'containers/LoadingOverlay';

const App = (): JSX.Element => {
    return (
        <Provider store={store}>
            <Router basename={process.env.PUBLIC_URL}>
                <Routes />
                <ToastContainer theme="dark" />
                <LoadingOverlay/>
            </Router>
        </Provider>
    );
};

export default App;
