const commonActions = {
    SET_TOTAL_ASSETS_COUNT: 'SET_TOTAL_ASSETS_COUNT',
    ADD_LOADED_ASSETS_COUNT: 'ADD_LOADED_ASSETS_COUNT',
    RESET_LOADED_ASSETS_COUNT: 'RESET_LOADED_ASSETS_COUNT',
    TOGGLE_MENU_ABOUT_MODAL: 'TOGGLE_MENU_ABOUT_MODAL',
    TOGGLE_MENU_TEAM_MODAL: 'TOGGLE_MENU_TEAM_MODAL',
    TOGGLE_MENU_ROADMAP_MODAL: 'TOGGLE_MENU_ROADMAP_MODAL',
    TOGGLE_MENU_RESIDENT_MODAL: 'TOGGLE_MENU_RESIDENT_MODAL',
    TOGGLE_TOMO_TOKEN_MODAL: 'TOGGLE_TOMO_TOKEN_MODAL',
    TOGGLE_TOMO_TV_MODAL: 'TOGGLE_TOMO_TV_MODAL',
    TOGGLE_FLOPPY_MODAL: 'TOGGLE_FLOPPY_MODAL',
    TOGGLE_KONBINI_WARS_MODAL: 'TOGGLE_KONBINI_WARS_MODAL',
    TOGGLE_METALHEADS_MODAL: 'TOGGLE_METALHEADS_MODAL',

    setTotalAssetsCount: (count: number): any => {
        return {
            type: commonActions.SET_TOTAL_ASSETS_COUNT,
            payload: {
                count,
            },
        }
    },

    addLoadedAssetsCount: (): any => {
        return {
            type: commonActions.ADD_LOADED_ASSETS_COUNT,
        }
    },

    resetLoadedAssetsCount: (): any => {
        return {
            type: commonActions.RESET_LOADED_ASSETS_COUNT,
        }
    },

    toggleMenuAboutModal: (): any => {
        return {
            type: commonActions.TOGGLE_MENU_ABOUT_MODAL,
        }
    },

    toggleMenuTeamModal: (): any => {
        return {
            type: commonActions.TOGGLE_MENU_TEAM_MODAL,
        }
    },

    toggleMenuRoadmapModal: (): any => {
        return {
            type: commonActions.TOGGLE_MENU_ROADMAP_MODAL,
        }
    },

    toggleMenuResidentModal: (): any => {
        return {
            type: commonActions.TOGGLE_MENU_RESIDENT_MODAL,
        }
    },

    toggleTomoTokenModal: (): any => {
        return {
            type: commonActions.TOGGLE_TOMO_TOKEN_MODAL,
        }
    },

    toggleTomoTvModal: (): any => {
        return {
            type: commonActions.TOGGLE_TOMO_TV_MODAL,
        }
    },

    toggleFloppyModal: (): any => {
        return {
            type: commonActions.TOGGLE_FLOPPY_MODAL,
        }
    },

    toggleKonbiniWarsModal: (): any => {
        return {
            type: commonActions.TOGGLE_KONBINI_WARS_MODAL,
        }
    },
    
    toggleMetalheadsModal: (): any => {
        return {
            type: commonActions.TOGGLE_METALHEADS_MODAL,
        }
    },
};

export default commonActions;
